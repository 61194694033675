.question {
  margin-top: 30px;

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 14px;
  }

  &__input {
    width: 100%;
    height: 226px;
    border-radius: 0;
  }
}
