
@import "~bootstrap/scss/bootstrap";
.app-text {
  font-family: Montserrat;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
}
