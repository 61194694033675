@import '../../../assets/stylesheets/mixins.scss';

.breadcrumbs {
  margin-top: 20px;
  margin-bottom: 40px;
  flex-direction: row;

  @include flex();
  @include align-items(center);

  &__container {
    @include flex();
    @include align-items(center);

    i{
      margin: -2px 15px 0;
      font-size: 1.8rem; 
    }
  }

  &__text {
    font-size: 18px;
    margin-bottom: 5px;
    text-decoration: none;
  }

  &__text--link {
    font-weight: 600;
    cursor: pointer;
    color: #813091;
    text-decoration: underline;
  }
}
