//****************************
// Mixins with Vendor Prefixes
//****************************

@mixin flex-position($align, $justify){
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: $align;
  -moz-box-align: $align;
  -ms-flex-align: $align;
  -webkit-align-items: $align;
  align-items: $align;

  -webkit-box-pack: $justify;
  -moz-box-pack: $justify;
  -ms-flex-pack: $justify;
  -webkit-justify-content: $justify;
  justify-content: $justify;
}

@mixin flex{
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin align-items($align){
  -webkit-box-align: $align;
  -moz-box-align: $align;
  -ms-flex-align: $align;
  -webkit-align-items: $align;
  align-items: $align;
}

@mixin justify-content($justify){
  -webkit-box-pack: $justify;
  -moz-box-pack: $justify;
  -ms-flex-pack: $justify;
  -webkit-justify-content: $justify;
  justify-content: $justify;
}

@mixin flex-wrap($wrap){
  -webkit-box-lines: $wrap;
  -moz-box-lines: $wrap;
  -ms-flex-wrap: $wrap;
  -webkit-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin translate($x, $y){
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  transform: translate($x, $y);
}

///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}
