.section {
  padding-top: 60px;
  cursor: pointer;

  &__title {
    font-size: 25px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    font-size: 16px;
    padding-top: 30px;
  }

  &__icon {
    font-size: 43px;
    -webkit-transition: .035s ease;
    -moz-transition: .035s ease;
    -o-transition: .035s ease;
    transition: .035s ease;

    &.active {
      transform: rotate(-180deg);
    }
  }
}
